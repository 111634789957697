<template>
  <section id="hero">
    <v-container class="fill-height px-4 py-12">
      <video src="../../assets/incognit/clouds.mp4" muted loop autoplay>
      </video>
      <v-responsive class="d-flex align-center mx-auto hero-heading" height="100%" max-width="700" width="100%">
        <div class="hero-img">
          <v-img src="../../assets/incognit/OnlyCloud_Color.png"></v-img>
        </div>
        <div class="hero-text">
          <base-heading class="heading" size="text-h2" title="Incognit Cloud" weight="medium" />
          <base-subheading class="heading" weight="regular" title="SHARING A PASSION" />

        </div>
      </v-responsive>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'SectionHero',
  provide: {
    theme: { isDark: true },
  },

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

      return `calc(${height} - ${this.$vuetify.application.top}px)`
    },
  },
}
</script>
